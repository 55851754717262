import { useEffect } from 'react';

import _ from 'lodash';
import clsx from 'clsx';

import { trackEvent, trackPage } from '@nm-namshi-frontend/core/utils/analytics';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import Breadcrumbs from '@nm-namshi-frontend/core/page_components/shared/Breadcrumbs';
import ScrollToTop from '@nm-namshi-frontend/core/page_components/shared/ScrollToTop';
import { SectionLayout } from '@nm-namshi-frontend/services';
import { ShareCatalog } from '@nm-namshi-frontend/core/page_components/shared/share/ShareCatalog';

import styles from './ProductListingPage.module.scss';
import Filters from './Filters';
import PaginationContainer from './PaginationContainer';
import ProductListingContent from './ProductListingContent';
import EmptyListingPage from './EmptyListingPage';

const ProductListingPage = () => {
    const { catalogResponse, listingPagehasNoProducts } = useCatalog();
    const resultCount = catalogResponse.nav?.searchMeta.totalCount || 0;
    const isFiltersEmpty = !catalogResponse.nav?.filters;

    // tracking
    useEffect(() => {
        if (catalogResponse) {
            const skuList = _(catalogResponse.data)
                .flatMap((catalogItem: SectionLayout) => catalogItem?.columns)
                .flatMap((column) => column?.modules)
                .filter((item) => item && item?.type === 'productBox')
                .map((item) => item && item?.product && item?.product?.sku)
                .compact()
                .value();

            trackPage({
                event: 'page_plp',
                searchTerm: catalogResponse.nav?.searchMeta.query ?? '',
                catalog: catalogResponse,
                skuList,
                resultCount,
            });
        }
    }, [catalogResponse]); // careful - do not add other deps

    if (listingPagehasNoProducts)
        return (
            <div className={clsx('siteWidthContainer', styles.container)}>
                <EmptyListingPage />
            </div>
        );

    // Renderers
    const FiltersContent = () => (
        <section className={styles.filtersOuterContainer}>
            <div className={styles.filtersInnerContainer}>
                <Filters />
            </div>
        </section>
    );

    const ListingsContent = () => (
        <section className={clsx(styles.listingContainer, { [styles.stretch]: isFiltersEmpty })}>
            <ProductListingContent />
            <PaginationContainer className={styles.paginationContainer} />
        </section>
    );

    return (
        <div className={clsx('siteWidthContainer')}>
            <ScrollToTop />
            <div className={styles.header}>
                <span>
                    {catalogResponse.meta?.h1 && <h1>{catalogResponse.meta?.h1}</h1>}
                    <div className={styles.breadcrumbsWrapper}>
                        <Breadcrumbs />
                    </div>
                </span>
                <ShareCatalog
                    outlined
                    pageType="listing"
                    referralMessage={catalogResponse?.meta?.title || ''}
                    onShare={({ url }) => {
                        trackEvent({
                            event: 'linkShare',
                            link: url,
                            pageType: 'listing',
                        });
                    }}
                />
            </div>

            <div className={styles.container}>
                {!isFiltersEmpty && <FiltersContent />}
                {ListingsContent()}
            </div>
        </div>
    );
};

export default ProductListingPage;
