'use client';

import React from 'react';

import { useTranslation } from 'react-i18next';

import getImages from '@nm-namshi-frontend/core/utils/images';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';

import styles from './HomeBrandsBanner.module.scss';

const brands = [
    'nike',
    'missguided',
    'topshop',
    'puma',
    'new_look',
    'adidas',
    'forever_21',
    'calvin_klein',
    'jordan',
    'skechers',
    'new_balance',
] as const;

type TBrand = (typeof brands)[number];

const HomeBrandsBanner = () => {
    const { t } = useTranslation();
    const { locale } = useAppPathname();

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>{t('labels-you-love')}</div>
            <div className={styles.brandsGroup}>
                {brands.map((brand: TBrand) => (
                    <AlteredLink locale={locale} key={brand} href={`/${brand}`}>
                        <img className={styles.brandImage} alt={brand} src={getImages().brands[brand]} />
                    </AlteredLink>
                ))}
            </div>
        </div>
    );
};

export default HomeBrandsBanner;
