import React, { useEffect, useRef } from 'react';

import { isEmpty, isUndefined } from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ProductFullResponse, ProductHeadline } from '@nm-namshi-frontend/services';
import ProductImage from '@nm-namshi-frontend/core/page_components/shared/product/ProductImage';
import { getImageUrl, IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import useIsInViewport from '@nm-namshi-frontend/core/hooks/useIsInViewport';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { WIDGET_IDS } from '@nm-namshi-frontend/core/config';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './GroupedProducts.module.scss';

type TProps = {
    productData: ProductFullResponse;
};

const GroupedProducts: React.FC<TProps> = ({ productData }) => {
    const { t } = useTranslation();
    const setGroupedProductsModal = useLayoutStore((s) => s.setGroupedProductsModal);
    const { isMobile } = useAppContext();

    const { groupName, groupValue, groupedHasSwatches, groupedProducts, groupedProductsInStockCount, parentSku, sku } =
        productData;

    const handleViewAll = () => {
        trackEvent({
            event: 'genericButtonClick',
            action: 'colorGroupClick',
            pageType: 'pdp',
            sku,
        });

        setGroupedProductsModal({
            show: true,
            product: productData,
            widgetId: WIDGET_IDS.PRODUCT_OPTIONS_MODAL_PDP,
        });
    };

    if (isEmpty(groupedProducts) || isUndefined(groupedProducts)) return null;

    return (
        <section className={styles.container}>
            <div className={styles.header}>
                <div className={styles.attribute}>
                    <div className={styles.label}>{groupName}</div>
                    <div className={styles.value}>{groupValue}</div>
                </div>
                <button type="button" className={styles.viewAllBtn} onClick={handleViewAll}>
                    <div>
                        {t('viewAll')} {groupedProductsInStockCount && <>({groupedProductsInStockCount})</>}
                    </div>
                    {isMobile && <Icon name="chevronDownBold" size={14} />}
                </button>
            </div>

            <div className={styles.horizontalWrapper}>
                {groupedProducts.map((item) => (
                    <GroupedProduct
                        key={item.imageKey}
                        item={item}
                        parentSku={parentSku}
                        isSwatch={groupedHasSwatches}
                    />
                ))}
            </div>
        </section>
    );
};

const GroupedProduct: React.FC<{ item: ProductHeadline; parentSku: string; isSwatch?: boolean }> = ({
    isSwatch = false,
    item,
    parentSku,
}) => {
    const { locale } = useAppPathname();
    const ref = useRef<HTMLDivElement>(null);
    const { isViewed } = useIsInViewport({ ref });

    const { imageKey, parentSku: groupItemParentSku, sku, stockInfo, title, uri } = item;
    const isOOS = stockInfo?.code === 'out_of_stock';
    const isSelected = groupItemParentSku === parentSku;

    useEffect(() => {
        if (isViewed) {
            trackEvent({
                event: 'impression_detail',
                sku,
                pageType: 'pdp',
                parentSku: groupItemParentSku,
                imagePath: getImageUrl(imageKey),
                widgetId: WIDGET_IDS.PRODUCT_OPTIONS_PDP,
            });
        }
    }, [isViewed]);

    return (
        <AlteredLink locale={locale} href={uri} replace>
            <div
                ref={ref}
                className={clsx(styles.item, {
                    [styles.outOfStock]: isOOS,
                    [styles.selected]: isSelected,
                    [styles.swatch]: isSwatch,
                })}
                style={isSwatch ? { backgroundColor: item.namshiSwatch } : {}}
            >
                {!isSwatch && (
                    <ProductImage
                        size={IMAGE_SIZE.ML}
                        imageKey={imageKey}
                        containerClassname={styles.itemImg}
                        altText={title || ''}
                    />
                )}
            </div>
        </AlteredLink>
    );
};

export default GroupedProducts;
