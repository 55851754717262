import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { SearchSortBy, SearchSortDir } from '@nm-namshi-frontend/services';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { showPageLoader } from '@nm-namshi-frontend/core/utils/pageLoader';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import styles from './TotalCountAndSort.module.scss';

const TotalCountAndSort = () => {
    const { t } = useTranslation('catalog');
    const { catalogResponse } = useCatalog();

    const totalCount = catalogResponse.nav?.searchMeta.totalCount || 0;

    if (totalCount === 0) {
        // #TODO: Report
    }

    // Renderers
    const renderTotalCount = () =>
        !!totalCount && (
            <div className={styles.totalCount}>
                {t('_result-count', { count: totalCount, formattedCount: totalCount.toLocaleString() })}
            </div>
        );

    const renderSortAndPageCount = () => (
        <span className={styles.sortAndPageCount}>
            <SortSelector />
        </span>
    );

    return (
        <section className={styles.container}>
            {renderTotalCount()}
            {renderSortAndPageCount()}
        </section>
    );
};

const SortSelector = () => {
    const { applySort, catalogResponse } = useCatalog();
    const { t } = useTranslation('catalog');

    const ref = useRef<HTMLSelectElement>(null);

    const sortDropdownOptions = catalogResponse.nav?.sortInfo.map(({ by, dir, isActive, name }) => ({
        value: { by, dir, isActive },
        label: name,
    }));

    const onSortOptionSelect = (selectedSortOption: { by: SearchSortBy; dir: SearchSortDir; isActive: boolean }) => {
        if (!selectedSortOption.isActive) {
            showPageLoader();
            applySort(selectedSortOption.by, selectedSortOption.dir);
            trackEvent({
                event: 'sortClicked',
                sortKey: selectedSortOption.by,
                sortDirection: selectedSortOption.dir,
            });
        }
    };

    if (!sortDropdownOptions) return <div />;

    const activeSortOptionIndex = sortDropdownOptions.findIndex(({ value }) => value.isActive);

    return (
        <div className={styles.sortBySelectWrapper}>
            <div className={styles.selectElement}>
                <label htmlFor="select-sort">{t('sort-label')}</label>
                <select
                    ref={ref}
                    id="select-sort"
                    value={activeSortOptionIndex}
                    onChange={(e) => onSortOptionSelect(sortDropdownOptions[e.target.value as any as number].value)}
                >
                    {sortDropdownOptions.map((item, idx) => (
                        <option key={item.label} value={idx}>
                            {item.label}
                        </option>
                    ))}
                </select>
            </div>
            <Icon name="chevronDownBold" size={15} />
        </div>
    );
};

export default TotalCountAndSort;
