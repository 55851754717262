'use client';

import React, { useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import RawHTML from '@nm-namshi-frontend/core/components/RawHTML';
import { isSchmatalog } from '@nm-namshi-frontend/core/utils/helper';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import useGetUserOverlay from '@nm-namshi-frontend/core/hooks/useGetUserOverlay';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';

import styles from './CustomerOverlay.module.scss';

const IS_MOBILE = isSchmatalog();

enum OverlayType {
    ORDER_ON_HOLD = 'order_on_hold',
    PAYMENT_RETRY = 'payment_retry',
}

type TProps = {
    isUserLoggedIn: boolean;
};

const CustomerOverlay: React.FC<TProps> = ({ isUserLoggedIn = false }) => {
    const { locale } = useAppPathname();
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    const overlayData = useGetUserOverlay({ isUserLoggedIn });
    useEffect(() => {
        if (overlayData) {
            setIsOverlayOpen(true);
        }
    }, [overlayData]);
    const { icon, overlay, type } = overlayData || {};
    const isOrderOnHold = type === OverlayType.ORDER_ON_HOLD;
    const isPaymentRetry = type === OverlayType.PAYMENT_RETRY;

    const closeOverlay = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOverlayOpen(false);
    };

    const textColor = useMemo(() => {
        if (isOrderOnHold) {
            return 'white';
        }
        return 'offBlack';
    }, [isOrderOnHold]);

    if (!overlay || !isOverlayOpen || isPaymentRetry) {
        return null;
    }
    return (
        <AlteredLink
            locale={locale}
            className={clsx(styles.overlayContainer, { [styles.mobileOverlayContainer]: IS_MOBILE })}
            style={{ backgroundColor: overlay.color }}
            href={overlay.redirectUrl}
        >
            <span className={clsx(styles.textContainer, { [styles.onHoldOverlayText]: isOrderOnHold })}>
                {icon && <Icon name={icon.iconType} size={18} color={textColor} />}
                {overlay.text && (
                    <RawHTML
                        html={overlay.text}
                        className={clsx({
                            [styles.onHoldText]: isOrderOnHold,
                        })}
                    />
                )}
            </span>

            {IS_MOBILE && !overlay.isClosable && <Icon color={textColor} name="chevronForward" size={24} />}

            {overlay.isClosable && (
                <div className={styles.closeBtnWrapper}>
                    <button className={styles.closeBtn} onClick={(e) => closeOverlay(e)} type="button">
                        <Icon color={textColor} name="close" size={14} />
                    </button>
                </div>
            )}
        </AlteredLink>
    );
};

export default CustomerOverlay;
