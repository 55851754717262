import React, { useEffect, useState, FunctionComponent as FC } from 'react';

import { debounce, throttle } from 'lodash';
import clsx from 'clsx';

import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './ScrollToTop.module.scss';

type TScrollToTopProps = {
    buttonVisibilityThreshold?: number;
    bottomMargin?: number | string;
};

const BUTTON_VISIBILITY_THRESHOLD = 200;

const ScrollToTop: FC<TScrollToTopProps> = ({
    bottomMargin,
    buttonVisibilityThreshold = BUTTON_VISIBILITY_THRESHOLD,
}) => {
    const [showButton, setShowButton] = useState(false);

    const scrollToTop = throttle(
        () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        2000,
        { leading: true, trailing: false },
    );

    const handleScrollEvent = () => {
        setShowButton(window.scrollY > buttonVisibilityThreshold);
    };

    useEffect(() => {
        const debounceEvent = debounce(handleScrollEvent, 30);

        window.addEventListener('scroll', debounceEvent);
        return () => {
            window.removeEventListener('scroll', debounceEvent);
            debounceEvent.cancel();
            scrollToTop.cancel();
        };
    }, []);

    return (
        <>
            <button
                type="button"
                className={clsx(styles.scroll_to_top, showButton && styles.is_visible)}
                onClick={scrollToTop}
                style={
                    showButton && bottomMargin
                        ? {
                              bottom: bottomMargin,
                          }
                        : {}
                }
            >
                <Icon name="scrollTop" size={40} />
            </button>
        </>
    );
};

export default ScrollToTop;
