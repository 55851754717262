import React from 'react';

import { useTranslation } from 'react-i18next';
import { get, isEmpty, isString } from 'lodash';
import { useSearchParams } from 'next/navigation';

import CategoryFilterBreadcrumbs from '@nm-namshi-frontend/core/page_components/productListingPage/CategoryFilterBreadcrumbs';
import InformationDrawer from '@nm-namshi-frontend/core/page_components/productDetailPage/InformationDrawer';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { BooleanSelectFilter, CategoryTreeResponse, SearchFilterResponse } from '@nm-namshi-frontend/services/';
import Facet from '@nm-namshi-frontend/core/page_components/productListingPage/Facet';
import { showPageLoader } from '@nm-namshi-frontend/core/utils/pageLoader';

import styles from './FacetDrawer.module.scss';

const ALWAYS_OPEN = ['brand_code', 'category'];

type TFacetDrawerProps = {
    facetData: SearchFilterResponse;
    trackFilter?: boolean;
    initialOpened?: string | null;
};

const FacetDrawer = ({ facetData, initialOpened, trackFilter }: TFacetDrawerProps) => {
    const { code, data, name, type } = facetData;
    const { t } = useTranslation('catalog');
    const searchParams = useSearchParams();

    const { catalogResponse, clearFilters, filters } = useCatalog();

    if (!['multiselect', 'singleselect', 'range', 'boolean'].includes(type)) return null;

    let shouldShowClear = false;
    if (type === 'range') {
        const originalFilter = catalogResponse.nav?.filters.find((f) => f.code === code)?.data;
        if (!Array.isArray(originalFilter)) {
            if (!isString(originalFilter)) {
                // The BE type used here SearchFilterResponse supports multiple filter structures
                const originalFilterDataMax = (originalFilter as Record<string, number>)?.max;
                const originalFilterDataMin = (originalFilter as Record<string, number>)?.min;

                const appliedMax = get(filters, `f.${code}.max`);
                const appliedMin = get(filters, `f.${code}.min`);

                if (appliedMax !== undefined && appliedMin !== undefined) {
                    if (originalFilterDataMax !== appliedMax || originalFilterDataMin !== appliedMin) {
                        shouldShowClear = true;
                    }
                }

                if (searchParams?.get(`f[${code}][min]`) || searchParams?.get(`f[${code}][max]`)) {
                    shouldShowClear = true;
                }
            }
        }
        if (!isEmpty(data) && Array.isArray(data)) {
            // For selects, make sure there is atleast one active filter
            shouldShowClear = data.some((filterData) => 'isActive' in filterData && filterData?.isActive);
        }
    } else if (type === 'multiselect') {
        if (!isEmpty(data) && Array.isArray(data)) {
            // For selects, make sure there is atleast one active filter
            shouldShowClear = data.some((filterData) => 'isActive' in filterData && filterData?.isActive);
        }
    } else if (type === 'boolean') {
        shouldShowClear = (data as BooleanSelectFilter)?.isActive;
    }

    const onFacetClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();

        clearFilters(`f.${code}`, true);
        showPageLoader();
    };

    if (shouldShowClear) {
        return (
            <InformationDrawer
                headingClassName={styles.heading}
                heading={name}
                isOpen={!initialOpened}
                headingCta={t('clear-cta')}
                headingCtaAction={onFacetClear}
            >
                <Facet facetData={facetData} facetLocation="drawer" trackFilter={trackFilter} />
            </InformationDrawer>
        );
    }

    return (
        <InformationDrawer
            headingClassName={styles.heading}
            heading={name}
            isOpen={initialOpened ? initialOpened === code : ALWAYS_OPEN.includes(code)}
        >
            <>
                {facetData.code === 'category' && (
                    <CategoryFilterBreadcrumbs
                        categoryData={facetData?.data as CategoryTreeResponse[]}
                        facetLocation="drawer"
                    />
                )}
                <Facet facetData={facetData} facetLocation="drawer" trackFilter={trackFilter} />
            </>
        </InformationDrawer>
    );
};

export default FacetDrawer;
