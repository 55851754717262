'use client';

import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Skeleton from '@nm-namshi-frontend/core/components/loaders/Skeleton';
import CountDownTimer from '@nm-namshi-frontend/core/page_components/shared/CountdownTimer';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import { ExtrasResponse, DeliveryType } from '@nm-namshi-frontend/services';
import { TConfigIcons } from '@nm-namshi-frontend/core/theme/icon';

import styles from './ShippingEstimates.module.scss';

const ICON_MAP: Record<DeliveryType, TConfigIcons> = {
    fastest: 'box',
    international: 'plane',
    standard: 'fastTruck',
};

type TProps = {
    extrasData?: ExtrasResponse;
    isExtrasLoading: boolean;
};

const ShippingEstimates: React.FC<TProps> = ({ extrasData, isExtrasLoading }) => {
    const { t } = useTranslation();

    if (!extrasData?.extras && !isExtrasLoading) return null;

    const { deliveryOptions, estimatedDeliveryText, isDeliverable } = extrasData?.extras || {};

    if (isExtrasLoading)
        return (
            <div className={clsx(styles.container, styles.loadingContainer)}>
                <Skeleton height="30px" />
                <Skeleton height="30px" />
            </div>
        );

    if (!isDeliverable && estimatedDeliveryText)
        return (
            <div className={styles.container}>
                <div className={clsx(styles.shippingItem, styles.undeliverable)}>{parse(estimatedDeliveryText)}</div>
            </div>
        );

    return (
        <div className={styles.container}>
            {deliveryOptions?.map((item) => {
                const expiry = item.expirationDate && Math.floor(new Date(item.expirationDate).getTime() / 1000);

                return (
                    <div
                        key={item.shippingCode}
                        className={clsx(styles.shippingItem, {
                            [styles.rocket]: item.shippingCode === 'rocket',
                            [styles.international]: item.deliveryType === 'international',
                        })}
                    >
                        <Icon
                            name={item.shippingCode === 'rocket' ? 'rocket' : ICON_MAP[item.deliveryType]}
                            size={25}
                        />
                        <div className={styles.text}>
                            <span>{parse(item.text)}</span>
                            {expiry && (
                                <div className={styles.expiry}>
                                    <CountDownTimer
                                        label={t('order-within')}
                                        className={styles.timer}
                                        showUnits
                                        hideSeconds
                                        endTime={expiry}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ShippingEstimates;
