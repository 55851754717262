import React from 'react';

import { useTranslation } from 'react-i18next';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import { ReviewSummary } from '@nm-namshi-frontend/services';
import { getFormattedCount } from '@nm-namshi-frontend/core/utils/helper';

import styles from './RatingsSummary.module.scss';

type TProps = {
    review: ReviewSummary;
};

const RatingsSummary: React.FC<TProps> = ({ review }) => {
    const { t } = useTranslation('catalog');

    const total = review.ratingCount;

    return (
        <div className={styles.mainContainer}>
            <div className={styles.title}>{t('ratingsTitle')}</div>
            <div className={styles.container}>
                <div>
                    <div className={styles.brief}>
                        <Icon name="star" size={20} />
                        <div className={styles.ratingAvg}>{review.ratingAvg}</div>
                    </div>

                    <div className={styles.ratingCount}>
                        {t('_rating-count', { count: total, formattedCount: getFormattedCount(total) })}
                    </div>
                </div>
                <div className={styles.distList}>
                    {Object.entries(review.ratingDistribution).map(([rating, count]) => (
                        <div key={rating} className={styles.distItem}>
                            <div className={styles.distBrief}>
                                <Icon name="star" size={10} color="var(--clr-dark)" />
                                {rating}
                            </div>
                            <div
                                className={styles.distItemBar}
                                style={
                                    {
                                        '--progress': `${100 - (count / total) * 100}%`,
                                    } as React.CSSProperties
                                }
                            />
                            <div className={styles.distItemCount}>{getFormattedCount(count)}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RatingsSummary;
