/* eslint-disable @next/next/no-img-element */
import React from 'react';

import { first } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';

import InformationDrawer from '@nm-namshi-frontend/core/page_components/productDetailPage/InformationDrawer';
import { ProductFullResponse } from '@nm-namshi-frontend/services';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import RawHTML from '@nm-namshi-frontend/core/components/RawHTML';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import PDPBrand from '@nm-namshi-frontend/core/components/PDPBrand';
import { getHelpCenterLink } from '@nm-namshi-frontend/core/page_components/shared/footer/LinksGroups';

import styles from './CoreDetails.module.scss';

type TProps = {
    productData: ProductFullResponse;
};
const descriptionKey = 'Description';

const CoreDetails = ({ productData }: TProps) => {
    const { t } = useTranslation('catalog');
    const { locale } = useAppPathname();
    const { attributes, description, highlights, isReturnable } = productData;

    const renderProductHighlights = () => {
        if (!highlights) return null;
        return (
            <div className={styles.specificationsContainer}>
                <InformationDrawer
                    isOpen
                    headingClassName={styles.drawerWrapper}
                    heading={t('product-highlights-label')}
                >
                    <ul className={styles.list}>
                        {highlights.map((highlight) => (
                            <li className={styles.listItem} key={highlight}>
                                {highlight}
                            </li>
                        ))}
                    </ul>
                </InformationDrawer>
            </div>
        );
    };
    const renderDescription = () => {
        if (!description) return null;
        return (
            <div className={styles.specificationsContainer}>
                <InformationDrawer isOpen headingClassName={styles.drawerWrapper} heading={t('product-description')}>
                    <RawHTML className={styles.descText} html={description} />
                </InformationDrawer>
            </div>
        );
    };
    const renderDetails = () => {
        if (!attributes) return null;
        const renderingAttributes = attributes
            .filter((attribute) => first(Object.values(attribute)))
            .filter((attribute) => first(Object.keys(attribute)) !== descriptionKey);
        return (
            <div className={styles.specificationsContainer}>
                <InformationDrawer isOpen headingClassName={styles.drawerWrapper} heading={t('product-details-label')}>
                    <ul className={styles.list}>
                        {renderingAttributes.map((attribute, index) => {
                            const attributeLabel = first(Object.keys(attribute));
                            const attributeValue = attribute[`${attributeLabel}`];

                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <li className={styles.attributeItem} key={`${first(attributeLabel)}-${index}`}>
                                    <div className={styles.attributeRow}>
                                        <span className={clsx(styles.attributeColumn, styles.label)}>
                                            {attributeLabel}
                                        </span>
                                        <span className={styles.attributeColumn}>{attributeValue}</span>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </InformationDrawer>
            </div>
        );
    };

    const renderReturnDetails = () => (
        <div className={styles.specificationsContainer}>
            <InformationDrawer isOpen headingClassName={styles.drawerWrapper} heading={t('delivery-and-returns-label')}>
                <p>
                    {isReturnable ? (
                        <Trans t={t} i18nKey="_delivery-and-returns-description">
                            <AlteredLink
                                locale={locale}
                                href={getHelpCenterLink(locale, 'returns')}
                                className={styles.boldLink}
                                target="_blank"
                            >
                                here
                            </AlteredLink>
                        </Trans>
                    ) : (
                        <Trans t={t} i18nKey="_non-returnable-description">
                            <AlteredLink
                                locale={locale}
                                href={getHelpCenterLink(locale, 'returns')}
                                className={styles.boldLink}
                                target="_blank"
                            >
                                here
                            </AlteredLink>
                        </Trans>
                    )}
                </p>
            </InformationDrawer>
        </div>
    );

    const renderPDPBrand = () => (
        <div className={clsx(styles.specificationsContainer, styles.pdpBrandWrapper)}>
            <PDPBrand productData={productData} />
        </div>
    );

    const renderSpecifications = () => (
        <div className={styles.productDetails}>
            {renderPDPBrand()}
            {renderDescription()}
            {renderProductHighlights()}
            {renderDetails()}
            {renderReturnDetails()}
        </div>
    );

    return <div className={styles.container}>{renderSpecifications()}</div>;
};

export default CoreDetails;
