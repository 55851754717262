import { useQuery } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import useOrdersStore from '@nm-namshi-frontend/core/stores/useOrdersStore';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';

type TProps = {
    isUserLoggedIn: boolean;
};

const useGetUserOverlay = ({ isUserLoggedIn = false }: TProps) => {
    const setDoesUserHaveOrderOnHold = useOrdersStore((store) => store.setDoesUserHaveOrderOnHold);
    const { data: overlayData } = useQuery({
        queryKey: [REACT_QUERY_KEYS.GET_CUSTOMER_OVERLAY],
        queryFn: () => getApiInstance().customerOverlay.customerOverlay({}),
        enabled: isUserLoggedIn,
        onSuccess: (data) => {
            if (data.type === 'order_on_hold') {
                setDoesUserHaveOrderOnHold(true);
            }
        },
    });
    return overlayData;
};

export default useGetUserOverlay;
