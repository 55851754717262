'use client';

import React from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { SEOResponse } from '@nm-namshi-frontend/services';
import { TLocaleSEO } from '@nm-namshi-frontend/core/types';
import { isSchmatalog } from '@nm-namshi-frontend/core/utils/helper';

import styles from './PopularSearches.module.scss';
import AlteredLink from './AlteredLink';

type TProps = {
    locale: TLocaleSEO;
    searchesList: SEOResponse['footerPopularSearches'] | null;
};

const PopularSearches = ({ locale, searchesList }: TProps) => {
    const { t } = useTranslation('common');
    if (!searchesList?.length) return null;

    const isMobile = isSchmatalog();

    return (
        <footer className={clsx(styles.popularSearchesOuterContainer, isMobile ? styles.isMobile : null)}>
            <div className={clsx('siteWidthContainer', styles.popularSearchesInnerContainer)}>
                <h5 className={styles.heading}>{t('footer.popular-searches')}</h5>
                <div className={styles.searchesListContainer}>
                    {searchesList.map(({ keyword, targetUrl }, index) => (
                        <AlteredLink
                            key={`${keyword}${targetUrl}-item-${index + 1}`}
                            locale={locale}
                            className={styles.searchItem}
                            href={`/${locale}${targetUrl}`}
                        >
                            {keyword}
                        </AlteredLink>
                    ))}
                </div>
            </div>
        </footer>
    );
};

export default PopularSearches;
