import React from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { CustomerDataResponse } from '@nm-namshi-frontend/services/index';
import Button from '@nm-namshi-frontend/core/components/Button';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useConfigContext from '@nm-namshi-frontend/core/contexts/ConfigContext';
import { convertPrice } from '@nm-namshi-frontend/core/utils/helper';
import HeartIcon from '@nm-namshi-frontend/core/components/HeartIcon';
import useWishlistSkus from '@nm-namshi-frontend/core/hooks/useWishlistSkus';

import styles from './WishlistConversionCTA.module.scss';

type TProps = {
    analyticsData: {
        parentSku: string;
        price: number;
        isRocket: boolean;
        isGlobal: boolean;
    };
    sku: string;
    parentSku: string;
};

const WishlistConversionCTA = ({ analyticsData, parentSku, sku }: TProps) => {
    const { t } = useTranslation('wishlist');
    const queryClient = useQueryClient();
    const { locale } = useAppPathname();
    const { currencyCodeEN } = getLocaleConfig(locale);
    const { setAuthModal } = useLayoutStore();
    const { checkIsInWishlist } = useWishlistSkus();

    const { conversionRates } = useConfigContext();

    const { data: userData } = useQuery([REACT_QUERY_KEYS.GET_CUSTOMER], () =>
        getApiInstance().customer.getCustomer({}),
    );

    const convertedPrice = convertPrice({
        conversionRates,
        price: analyticsData.price,
        toCurrency: currencyCodeEN,
    });

    const { isLoading: isAddingToWishlist, mutate: addToWishlist } = useMutation(
        () =>
            getApiInstance().wardrobe.addItem1({
                requestBody: {
                    parentSku: parentSku as string,
                    sku,
                },
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_ITEMS]);

                toast.success(t('toast.success-message-description'));

                return queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_PARENTSKUS]);
            },
            onError: (error: string) => {
                toast.error(error.toString());
            },
        },
    );

    const { isLoading: isRemovingFromWishlist, mutate: removeFromDefaultWishlist } = useMutation(
        () =>
            getApiInstance().wardrobe.deleteItem({
                parentSku,
            }),

        {
            onSuccess: () => {
                queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_ITEMS]);
                toast.success(t('toast.remove'));

                return queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_PARENTSKUS]);
            },
            onError: (error: string) => {
                toast.error(error.toString());
            },
        },
    );

    const customerData = (userData as CustomerDataResponse)?.data;

    const handleAddToWishlistClick = () => {
        if (!customerData) {
            setAuthModal({ show: true, onSuccess: handleToggleWishlist });
            return;
        }

        handleToggleWishlist();
    };

    const handleToggleWishlist = () => {
        if (checkIsInWishlist(parentSku)) {
            removeFromDefaultWishlist();

            trackEvent({
                event: 'wishlist_remove',
                sku,
                parentSku: analyticsData.parentSku,
                isRocket: analyticsData.isRocket,
            });
        } else {
            addToWishlist();

            trackEvent({
                event: 'wishlist_add',
                sku,
                parentSku: analyticsData.parentSku,
                price: convertedPrice,
                currency: currencyCodeEN,
                isRocket: analyticsData.isRocket,
                isGlobal: analyticsData.isGlobal,
            });
        }
    };

    const isLoading = isAddingToWishlist || isRemovingFromWishlist;

    const isAdded = checkIsInWishlist(parentSku);

    return (
        <Button variant="secondary" onClick={handleAddToWishlistClick} disabled={isLoading}>
            <div className={styles.ctaContent} data-testid="addToWishlistButton">
                <HeartIcon active={isAdded} isLoading={isLoading} />
                {isAdded ? t('common.added-to-wishlist') : t('common.add-to-wishlist')}
            </div>
        </Button>
    );
};

export default WishlistConversionCTA;
