import React from 'react';

import styles from './ProgressBar.module.scss';

type TProgressBar = {
    color?: string;
    progress: number;
    height?: number;
    sameFinishColor?: boolean;
    bgColor?: string;
};
const ProgressBar = ({
    bgColor = 'var(--color-grey3)',
    color = 'var(--color-system-orange-dark)',
    height = 6,
    progress,
    sameFinishColor = false,
}: TProgressBar) => {
    const finishColor = sameFinishColor ? color : 'var(--color-green-1)';
    return (
        <div className={styles.wrapper} style={{ height, backgroundColor: bgColor }}>
            <div
                className={styles.progress}
                style={{
                    width: `${progress}%`,
                    backgroundColor: progress !== 100 ? color : finishColor,
                    height,
                }}
            />
        </div>
    );
};

export default ProgressBar;
