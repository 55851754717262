import clsx from 'clsx';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import { TLocaleSEO } from '@nm-namshi-frontend/core/types';

import styles from './PlpPagination.module.scss';

// Optimal values for pagination component:
const ADJACENT_BUTTON_COUNT_PER_SIDE = 1; // The number of adjacent page buttons for the current selected page button on one side
const POLAR_ADJACENT_BUTTON_OVERRIDING_COUNT = 3; // Indicates threshold for the number of pages from either end of the pagination array, that must be shown if they intersect with the buttons adjacent to the current selected page button

type TProps = {
    className?: string;
    currentPage: number;
    totalPages: number;
    locale: TLocaleSEO;
};

const PlpPagination = ({ className, currentPage, locale, totalPages }: TProps) => {
    const { changePage } = useCatalog();

    if (totalPages === 1) {
        return null;
    }

    // Renderers
    const renderSinglePaginationItem = (pageNumber: number, isCurrentPage: boolean) => {
        const url = changePage(`${pageNumber}`);

        return (
            <AlteredLink
                locale={locale}
                href={url}
                key={`Pagenumber-${pageNumber}`}
                disabled={isCurrentPage}
                className={clsx(styles.paginationItem, isCurrentPage && styles.isSelected)}
            >
                {pageNumber}
            </AlteredLink>
        );
    };

    const renderBeforeAndIncludingSelectedButton = () => {
        const smallestAdjacentPageToRender = currentPage - ADJACENT_BUTTON_COUNT_PER_SIDE;

        if (smallestAdjacentPageToRender > POLAR_ADJACENT_BUTTON_OVERRIDING_COUNT) {
            // Show dots as selected page is too far from first page
            return (
                <>
                    {renderSinglePaginationItem(1, currentPage === 1)}
                    <span>...</span>
                    {[...Array(ADJACENT_BUTTON_COUNT_PER_SIDE + 1)].map((item, index) => {
                        const currentIteratedPageNumber = index + smallestAdjacentPageToRender;
                        const isCurrentPage = currentIteratedPageNumber === currentPage;

                        return renderSinglePaginationItem(currentIteratedPageNumber, isCurrentPage);
                    })}
                </>
            );
        }

        // Otherwise render all buttons from start until current page
        return (
            <>
                {[...Array(currentPage)].map((item, index) => {
                    const currentIteratedPageNumber = index + 1;
                    const isCurrentPage = currentIteratedPageNumber === currentPage;

                    return renderSinglePaginationItem(currentIteratedPageNumber, isCurrentPage);
                })}
            </>
        );
    };

    const renderAfterSelectedButton = () => {
        const largestAdjacentPageToRender = currentPage + ADJACENT_BUTTON_COUNT_PER_SIDE;

        if (largestAdjacentPageToRender < totalPages - POLAR_ADJACENT_BUTTON_OVERRIDING_COUNT + 1) {
            // Show dots as selected page is too far from last page
            return (
                <>
                    {[...Array(ADJACENT_BUTTON_COUNT_PER_SIDE)].map((item, index) => {
                        const currentIteratedPageNumber = index + currentPage + 1;

                        return renderSinglePaginationItem(currentIteratedPageNumber, false);
                    })}
                    <span>...</span>
                    {renderSinglePaginationItem(totalPages, totalPages === currentPage)}
                </>
            );
        }

        // Otherwise render all buttons from currentPage + 1 until last page
        return (
            <>
                {[...Array(totalPages - currentPage)].map((item, index) => {
                    const currentIteratedPageNumber = index + currentPage + 1;

                    return renderSinglePaginationItem(currentIteratedPageNumber, false);
                })}
            </>
        );
    };

    const renderPreviousPageButton = () => {
        const url = changePage(`${currentPage - 1}`);
        const isDisabled = currentPage <= 1;

        return (
            <AlteredLink
                locale={locale}
                href={url}
                disabled={isDisabled}
                className={clsx(styles.directionItem, styles.start, isDisabled && styles.disabled)}
                prefetch={!isDisabled}
            >
                <Icon name="chevronBackwardBold" size={25} />
            </AlteredLink>
        );
    };

    const renderNextPageButton = () => {
        const url = changePage(`${currentPage + 1}`);
        const isDisabled = currentPage >= totalPages;

        return (
            <AlteredLink
                locale={locale}
                href={url}
                disabled={isDisabled}
                className={clsx(styles.directionItem, styles.end, isDisabled && styles.disabled)}
                prefetch={!isDisabled}
            >
                <Icon name="chevronForwardBold" size={25} />
            </AlteredLink>
        );
    };

    return (
        <div className={clsx(styles.container, className)}>
            {renderPreviousPageButton()}
            {renderBeforeAndIncludingSelectedButton()}
            {renderAfterSelectedButton()}
            {renderNextPageButton()}
        </div>
    );
};

export default PlpPagination;
