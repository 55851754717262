import PageCms from '@nm-namshi-frontend/core/page_components/shared/cms/PageCms';
import { TSectionLayout } from '@nm-namshi-frontend/widgets/src/types/ModulesRenderer';

type TProps = {
    cmsData?: Array<TSectionLayout>;
};

const ProductCMSDetails = ({ cmsData }: TProps) => {
    if (!cmsData) return null;

    return <PageCms cmsData={cmsData} position="midHigh" pageType="pdp" />;
};

export default ProductCMSDetails;
