import React, { useMemo } from 'react';

import clsx from 'clsx';

import { ProductFullResponse, ProductVariant } from '@nm-namshi-frontend/services/index';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import { isSchmatalog } from '@nm-namshi-frontend/core/utils/helper';

import styles from './SizePills.module.scss';

const IS_MOBILE = isSchmatalog();

type TSizePillsProps = {
    onSelect: (sku: string) => void;
    selectedSku: string;
    productData: ProductFullResponse;
    renderSizeGuide: () => React.ReactNode;
};

const SizePills: React.FC<TSizePillsProps> = (props) => {
    const { onSelect, productData, renderSizeGuide, selectedSku } = props;

    const selectedVariant = useMemo(
        () => productData.variants?.find(({ sku: variantSku }) => variantSku === selectedSku),
        [!!productData.variants, selectedSku],
    );

    if (!productData.variants?.length) return null;

    return (
        <>
            <div className={styles.header}>
                <h4 className={styles.title} data-sizetitle>
                    {productData.variantName}
                </h4>
                <div className={styles.guideWrapper}>
                    {!IS_MOBILE && <Tags productData={productData} selectedVariant={selectedVariant} />}
                    {renderSizeGuide()}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.size_wrapper}>
                    {productData.variants?.map((variant) => (
                        <Pill selectedSku={selectedSku} onSelect={onSelect} key={variant.sku} variant={variant} />
                    ))}
                </div>
            </div>
            {IS_MOBILE && <Tags productData={productData} selectedVariant={selectedVariant} />}
        </>
    );
};

const Pill = ({
    onSelect,
    selectedSku,
    variant,
}: {
    selectedSku: string;
    variant: ProductVariant;
    onSelect: (sku: string) => void;
}) => {
    const isOOS = variant?.stockInfo?.code === 'out_of_stock';
    const isLowStock = variant?.stockInfo?.code === 'low_stock';
    const isSelected = variant.sku === selectedSku;

    return (
        <button
            dir="ltr"
            type="button"
            key={variant.sku}
            disabled={isOOS || isSelected}
            onClick={() => onSelect(variant.sku)}
            className={clsx(styles.size_variant, {
                [styles.oos]: isOOS,
                [styles.lowStock]: isLowStock,
                [styles.selected]: isSelected,
            })}
        >
            {variant.title}
            {variant.isRocket && (
                <div className={styles.iconWrapper}>
                    <Icon
                        name={isSelected ? 'rocketPlain' : 'rocket'}
                        size={isSelected ? 7 : 12}
                        color={isSelected ? 'theme' : undefined}
                    />
                </div>
            )}
            {variant.isGlobal && (
                <div className={styles.iconWrapper}>
                    <Icon
                        name={isSelected ? 'plane' : 'global'}
                        size={isSelected ? 17 : 18}
                        color={isSelected ? 'purple-l1' : undefined}
                    />
                </div>
            )}
        </button>
    );
};

const Tags: React.FC<{ productData: ProductFullResponse; selectedVariant?: ProductVariant }> = ({
    productData,
    selectedVariant,
}) => {
    const stockInfo = selectedVariant?.stockInfo || productData?.stockInfo;
    const rocketTag = selectedVariant?.isRocket && selectedVariant?.rocketTag;

    if (!stockInfo && !rocketTag) return null;

    return (
        <div className={styles.tags}>
            {stockInfo && (
                <span
                    className={clsx(styles.stockLabel, stockInfo.code === 'low_stock' && styles.lowStockLabel)}
                    style={{ color: stockInfo.color }}
                >
                    {stockInfo.label}
                </span>
            )}
            {rocketTag && (
                <div className={styles.rocketTag}>
                    <Icon name="rocket" size={18} />
                    {rocketTag}
                </div>
            )}
        </div>
    );
};

export default SizePills;
