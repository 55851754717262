import clsx from 'clsx';

import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { TSectionLayout } from '@nm-namshi-frontend/widgets/src/types/ModulesRenderer';

import styles from './DetailsPageLayout.module.scss';
import CoreDetails from './CoreDetails';
import ImageGallery from './ImageGallery';
import ProductConversion from './ProductConversion';
import ProductCMSDetails from './ProductCMSDetails';

const DetailsPageLayout = () => {
    const { catalogResponse } = useCatalog();

    // Avoiding type assertions/casting ...
    if (
        !(
            catalogResponse &&
            'type' in catalogResponse &&
            catalogResponse.type === 'details' &&
            'product' in catalogResponse.data
        )
    ) {
        return null;
    }

    const productData = catalogResponse.data.product;
    const cmsData = catalogResponse.data.cms;

    return (
        <section className={styles.container}>
            <div className={clsx(styles.innerContainer)}>
                <ImageGallery productData={productData} />
                <div className={styles.details_container}>
                    <ProductConversion productData={productData} />
                    <ProductCMSDetails cmsData={cmsData as TSectionLayout[]} />
                    <CoreDetails productData={productData} />
                </div>
            </div>
        </section>
    );
};

export default DetailsPageLayout;
