'use client';

import React from 'react';

import { createPortal } from 'react-dom';

import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './LightBox.module.scss';

export type TModalProps = {
    children: React.ReactNode;
    open: boolean;
    onClose: (arg0: React.SyntheticEvent) => void;
};

const LightBox = ({ children, onClose, open }: TModalProps) => {
    const bodyLock = 'body { overflow: hidden }';

    return (
        <>
            {createPortal(
                <>
                    {open && <style>{bodyLock}</style>}
                    <div className={styles.backdrop} onClick={onClose}>
                        <div className={styles.container}>
                            <div className={styles.close} onClick={onClose}>
                                <Icon name="close" size={32} color="white" />
                            </div>
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    </div>
                </>,
                document.body,
            )}
        </>
    );
};

export default LightBox;
