import React, { useState } from 'react';

import clsx from 'clsx';

import getImages from '@nm-namshi-frontend/core/utils/images';
import { TABBY_LEARN_MORE_EXTERNAL_URL } from '@nm-namshi-frontend/core/config';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import Modal from '@nm-namshi-frontend/core/components/Modal';
import { buildUrlWithQueryParams } from '@nm-namshi-frontend/core/utils/url';
import { TConversionRates } from '@nm-namshi-frontend/core/types';
import useConfigContext from '@nm-namshi-frontend/core/contexts/ConfigContext';
import { convertPrice } from '@nm-namshi-frontend/core/utils/helper';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './TabbySnippet.module.scss';

const DEFAULT_LOGO_RATIO = 41 / 16;
const DEFAULT_LOGO_HEIGHT = 21;

type TProps = {
    amount: number;
    containerStyle?: string;
};

type TTabbyLearnMoreModalProps = {
    isOpen: boolean;
    onClose: () => void;
    url: string;
};
const TabbyLearnMoreModal = ({ isOpen, onClose, url }: TTabbyLearnMoreModalProps) => (
    <Modal
        mobileHeight="90%"
        desktopHeight="80%"
        desktopMaxHeight="650px"
        desktopWidth="60%"
        desktopMaxWidth="700px"
        open={isOpen}
        onClose={onClose}
        containerClass={styles.iframeContentContainer}
        isContentCovering
    >
        <iframe className={styles.iframe} id="tabby_learn_more" title="Learn More" src={url} />
    </Modal>
);

const TabbySnippet = ({ amount, containerStyle }: TProps) => {
    const { locale } = useAppPathname();
    const { currencyCode, lang } = getLocaleConfig(locale);
    const { conversionRates } = useConfigContext();

    const [isLearnMoreModalVisible, setIsLearnMoreModalVisible] = useState(false);

    const localAmount = convertPrice({
        conversionRates: conversionRates as TConversionRates,
        price: amount,
        toCurrency: currencyCode,
    });

    const learnMoreModalBaseUrl = `${TABBY_LEARN_MORE_EXTERNAL_URL}/${lang}/`;
    const learnMoreModalUrl = buildUrlWithQueryParams(learnMoreModalBaseUrl, [
        ['price', localAmount?.toString()],
        ['currency', currencyCode],
    ]);

    return (
        <>
            <button
                className={clsx(styles.container, containerStyle)}
                onClick={() => setIsLearnMoreModalVisible(true)}
                type="button"
            >
                <img
                    src={getImages().logos.tabby}
                    width={DEFAULT_LOGO_RATIO * DEFAULT_LOGO_HEIGHT}
                    height={DEFAULT_LOGO_HEIGHT}
                    alt="Tabby"
                />
                <Icon name="infoCircle" size={20} color="grey2" />
            </button>
            <TabbyLearnMoreModal
                isOpen={isLearnMoreModalVisible}
                onClose={() => setIsLearnMoreModalVisible(false)}
                url={learnMoreModalUrl}
            />
        </>
    );
};

export default TabbySnippet;
