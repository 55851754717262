import React from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import Checkbox from '@nm-namshi-frontend/core/components/forms/Checkbox';
import { ApiError } from '@nm-namshi-frontend/services/';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import styles from './FouponToggle.module.scss';

type TProps = {
    sku: string;
    fouponText: string;
    isActive?: boolean;
    onExternalFouponToggle?: (isChecked: boolean) => void;
};

const FouponToggle = ({ fouponText, isActive, onExternalFouponToggle, sku }: TProps) => {
    const { toggleShowFullScreenLoader } = useLayoutStore();
    const rqClient = useQueryClient();
    const { t } = useTranslation('common');

    const { mutate: setFouponToSession } = useMutation(
        [REACT_QUERY_KEYS.SET_FOUPON],
        (isFouponEnabled: boolean) => {
            toggleShowFullScreenLoader(true);
            return getApiInstance().session.toggleFoupon({
                requestBody: {
                    sku,
                    useFoupon: isFouponEnabled,
                },
            });
        },
        {
            onSuccess: (data) => {
                rqClient.setQueryData([REACT_QUERY_KEYS.GET_ACTIVE_SESSION], data);
            },
            onError: (error: ApiError) => {
                toast.error(error.message);
            },
            onSettled: () => {
                toggleShowFullScreenLoader(false);
            },
        },
    );

    if (!fouponText) return <></>;

    return (
        <div className={styles.container}>
            <span className={styles.tag}>{t('foupon-label')}</span>
            <Checkbox
                id={`Foupon-${fouponText.substring(0, 5)}-${sku}`}
                checked={isActive}
                onChange={(e) => {
                    if (onExternalFouponToggle) {
                        // If the action on foupon checking/unchecking is decided by a consuming component, do not trigger the mutation
                        onExternalFouponToggle(e.target.checked);
                    } else {
                        setFouponToSession(e.target.checked);
                    }
                }}
                containerClass={styles.description}
            >
                {fouponText}
            </Checkbox>
        </div>
    );
};

export default FouponToggle;
