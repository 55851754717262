import QueryString from 'qs';

import { TDepartmentCode } from '@nm-namshi-frontend/core/types';
import { Brand, BrandListResponse } from '@nm-namshi-frontend/services';

import { slashifyUrl } from './url';

const arabicAlphabet = [
    'إ',
    'آ',
    'أ',
    'ا',
    'ب',
    'ت',
    'ث',
    'ج',
    'ح',
    'خ',
    'د',
    'ذ',
    'ر',
    'ز',
    'س',
    'ش',
    'ص',
    'ض',
    'ط',
    'ظ',
    'ع',
    'غ',
    'ف',
    'ق',
    'ك',
    'ل',
    'م',
    'ن',
    'ه',
    'و',
    'ي',
];

const englishAlphabet = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
];

const isAlphabet = (letter: string, isArabic: boolean) =>
    isArabic ? arabicAlphabet.includes(letter) : englishAlphabet.includes(letter);

export const getDefaultAlphabet = (isArabic: boolean) => (isArabic ? arabicAlphabet : englishAlphabet);

export const groupBrands = (data: BrandListResponse, isArabic: boolean, search: string) => {
    if (!data?.brands) return {};

    let searchedBrands = data.brands;

    if (search) {
        searchedBrands = data.brands.filter(({ brandName }) =>
            brandName.toLowerCase().includes(search.toLowerCase().trim()),
        );
    }

    const dict: Record<string, Brand[]> = {};

    if (data.letters) {
        for (let i = 0; i < data.letters?.length; i += 1) {
            const letter = data.letters[i].toLowerCase();
            if (isAlphabet(letter, isArabic)) dict[letter] = [];
        }
    }

    dict['#'] = [];

    for (let i = 0; i < searchedBrands.length; i += 1) {
        const brand = searchedBrands[i];
        const letter = brand.letterCode.toLowerCase();

        if (isAlphabet(letter, isArabic)) {
            dict[letter].push(brand);
        } else {
            dict['#'].push(brand);
        }
    }

    if (isArabic) {
        dict['أ'] = dict['أ'] || [];
        const arr = ['ا', 'إ', 'آ'];

        for (let i = 0; i < arr.length; i += 1) {
            dict['أ'] = [...dict['أ'], ...dict[arr[i]]];
            delete dict[arr[i]];
        }
    }

    return dict;
};

/**
 * @param {string} brandCode brand code
 * @param {string} departmentCode department code
 * @param {boolean} isBrandPage should the URL contain the brandCode as a path
 * @param {string} baseUrl url to be prepended(usually contains department + subdepartment info) from navigation-categories v5
 * @returns {string} url
 */
export const getBrandUrl = (
    brandCode: string,
    departmentCode?: TDepartmentCode | string | null, // Should be deprecating its usage once we move to relying on a baseUrl from onsite
    isBrandPage?: boolean,
    baseUrl?: string,
): string => {
    if (isBrandPage) {
        // Dont include departmentCode in brand url - for SEO
        return `/${brandCode}/`;
    }

    let slashifiedBaseUrl;
    if (baseUrl) {
        slashifiedBaseUrl = slashifyUrl(baseUrl, true, true);
    }

    // As part of navigation-categories v5, baseUrl will be dictated by onsite, as opposed to us building it
    if (baseUrl) {
        return `${slashifiedBaseUrl}${brandCode}/`;
    }

    // This should be a deprecated case
    if (departmentCode) return `/${departmentCode}/${brandCode}`;

    return brandCode;
};
