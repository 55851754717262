import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ROUTES } from '@nm-namshi-frontend/core/constants/uiConstants';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './Breadcrumbs.module.scss';
import AlteredLink from './AlteredLink';

type TBreadcrumbsProps = {
    className?: string;
};

const Breadcrumbs: React.FC<TBreadcrumbsProps> = (props) => {
    const { className } = props;

    const { t } = useTranslation('common');
    const { locale } = useAppContext();

    const { catalogResponse } = useCatalog();

    const { breadcrumbs } = catalogResponse;

    if (!breadcrumbs?.length) return null;

    return (
        <ul className={clsx(styles.list, className)}>
            <li key="breadcrumb_home">
                <AlteredLink locale={locale} href={ROUTES.HOME}>
                    {t('home')}
                </AlteredLink>
            </li>
            {breadcrumbs.map((term, idx) => (
                <li key={term.uri}>
                    <span className={styles.delimiter}>{'>'}</span>
                    <AlteredLink
                        locale={locale}
                        href={`/${locale}${term.uri}`}
                        disabled={idx === breadcrumbs.length - 1}
                    >
                        {term.title}
                    </AlteredLink>
                </li>
            ))}
        </ul>
    );
};

export default Breadcrumbs;
