import { useEffect } from 'react';

import _isEmpty from 'lodash/isEmpty';

import MultiWidgetPage from '@nm-namshi-frontend/core/page_components/shared/cms/MultiWidgetPage';
import { SectionLayout } from '@nm-namshi-frontend/services';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { trackPage } from '@nm-namshi-frontend/core/utils/analytics';
import { isHomePage } from '@nm-namshi-frontend/core/utils/helper';
import { addToDataLayer } from '@nm-namshi-frontend/core/utils/tagManager';
import CustomerOverlay from '@nm-namshi-frontend/core/page_components/shared/CustomerOverlay';
import { NotCleanUserAlert } from '@nm-namshi-frontend/core/migration/components/NotCleanUserAlert';
import useCustomerStore from '@nm-namshi-frontend/core/stores/useCustomerStore';

const StaticPage = () => {
    const { catalogResponse } = useCatalog();
    const customerData = useCustomerStore((store) => store.customerData);

    // tracking
    useEffect(() => {
        if (catalogResponse) {
            trackPage({
                event: 'page',
                pageType: 'static',
                analytics: catalogResponse?.analytics,
            });
            addToDataLayer({
                isHomepage: isHomePage(catalogResponse.canonicalUrl),
            });
        }
    }, [catalogResponse]); // careful - do not add other deps

    if (!catalogResponse) {
        return null;
    }

    // Check for response.html => render html static page like privacy policy

    // Check for response.data => list of modules
    const modules = catalogResponse.data as SectionLayout[];
    if (_isEmpty(modules)) {
        return null;
    }

    return (
        <div>
            <CustomerOverlay isUserLoggedIn={!!customerData} />
            <div style={{ marginBottom: 12, marginTop: 12 }}>
                <NotCleanUserAlert />
            </div>
            <MultiWidgetPage
                modules={modules}
                device="desktop"
                pageType="static"
                analyticsData={{ pageType: 'static' }}
            />
        </div>
    );
};

export default StaticPage;
