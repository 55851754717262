import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import clsx from 'clsx';

import { FreebieOffer, GWPOfferResponse } from '@nm-namshi-frontend/services';
import useWindowDimensions from '@nm-namshi-frontend/core/hooks/useWindowDimensions';
import { SCREEN_SIZES } from '@nm-namshi-frontend/core/constants/uiConstants';

import './swiper.css';
import GiftWithPurchaseBanner from './GiftWithPurchaseBanner';
import styles from './GiftWithPurchaseSwiper.module.scss';

type TGiftWithPurchaseSwiper = {
    offers?: Array<FreebieOffer> | Array<GWPOfferResponse>;
    showProgress?: boolean;
    className?: string;
};

const GiftWithPurchaseSwiper = ({ className, offers, showProgress = false }: TGiftWithPurchaseSwiper) => {
    const { width: viewportWidth } = useWindowDimensions();
    const isMobile = viewportWidth <= SCREEN_SIZES.screenMob;

    if (!offers?.length) return null;

    return (
        <div className={clsx(styles.wrapper, className)}>
            {offers.length > 1 ? (
                <Swiper
                    slidesPerView="auto"
                    spaceBetween={12}
                    pagination={{
                        clickable: true,
                        enabled: isMobile,
                    }}
                    className={styles.slider}
                    centeredSlidesBounds
                    modules={[Pagination]}
                >
                    {offers
                        .sort((a, b) => a.priority - b.priority)
                        .map((offer) => (
                            <SwiperSlide key={offer.filterKeyCode} className={styles.gwpItem}>
                                <GiftWithPurchaseBanner showProgress={showProgress} offer={offer} />
                            </SwiperSlide>
                        ))}
                </Swiper>
            ) : (
                <GiftWithPurchaseBanner showProgress={showProgress} offer={offers[0]} />
            )}
        </div>
    );
};

export default GiftWithPurchaseSwiper;
