import React, { useState } from 'react';

import clsx from 'clsx';

import getImages from '@nm-namshi-frontend/core/utils/images';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import Modal from '@nm-namshi-frontend/core/components/Modal';
import { TConversionRates } from '@nm-namshi-frontend/core/types';
import useConfigContext from '@nm-namshi-frontend/core/contexts/ConfigContext';
import { convertPrice } from '@nm-namshi-frontend/core/utils/helper';
import { getTamaraLearnMoreUrl } from '@nm-namshi-frontend/core/utils/payments';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './TamaraSnippet.module.scss';

const DEFAULT_LOGO_RATIO = 41 / 14;
const DEFAULT_LOGO_HEIGHT = 21;

type TProps = {
    amount: number;
    containerStyle?: string;
};

type TTamaraLearnMoreModalProps = {
    isOpen: boolean;
    onClose: () => void;
    url: string;
};
const TamaraLearnMoreModal = ({ isOpen, onClose, url }: TTamaraLearnMoreModalProps) => (
    <Modal
        mobileHeight="90%"
        desktopHeight="80%"
        desktopMaxHeight="650px"
        desktopWidth="60%"
        desktopMaxWidth="700px"
        open={isOpen}
        onClose={onClose}
        containerClass={styles.iframeContentContainer}
        isContentCovering
    >
        <iframe className={styles.iframe} id="tamara_learn_more" title="Learn More" src={url} />
    </Modal>
);

const TamaraSnippet = ({ amount, containerStyle }: TProps) => {
    const { locale } = useAppPathname();
    const { apiRegion, currencyCode, lang } = getLocaleConfig(locale);
    const { conversionRates } = useConfigContext();

    const [isLearnMoreModalVisible, setIsLearnMoreModalVisible] = useState(false);

    const localAmount = convertPrice({
        conversionRates: conversionRates as TConversionRates,
        price: amount,
        toCurrency: currencyCode,
    });

    const learnMoreModalUrl = getTamaraLearnMoreUrl(lang, localAmount, currencyCode, apiRegion);

    return (
        <>
            <button
                className={clsx(styles.container, containerStyle)}
                onClick={() => setIsLearnMoreModalVisible(true)}
                type="button"
            >
                <img
                    src={getImages(locale).logos.tamara}
                    width={DEFAULT_LOGO_RATIO * DEFAULT_LOGO_HEIGHT}
                    height={DEFAULT_LOGO_HEIGHT}
                    alt="Tamara"
                />
                <Icon name="infoCircle" size={20} color="grey2" />
            </button>
            <TamaraLearnMoreModal
                isOpen={isLearnMoreModalVisible}
                onClose={() => setIsLearnMoreModalVisible(false)}
                url={learnMoreModalUrl}
            />
        </>
    );
};

export default TamaraSnippet;
