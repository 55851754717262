'use client';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { isEmpty, isUndefined } from 'lodash';

import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';

import FacetDrawer from './FacetDrawer';
import styles from './Filters.module.scss';

type TFilterProps = {
    initialOpened?: string | null;
};
const Filters = ({ initialOpened }: TFilterProps) => {
    const { t } = useTranslation('catalog');

    const { catalogResponse, clearFilters, filters } = useCatalog();
    const facets = catalogResponse.nav?.filters;

    const areFiltersApplied = 'f' in filters && !isEmpty(filters.f);

    if (!facets) {
        return <div />;
    }

    const nonEmptyFacets = facets.filter(({ data }) => !isEmpty(data) && !isUndefined(data)); // Guard clause to protect against messy BE data

    if (isEmpty(nonEmptyFacets)) return null;

    return (
        <div className={styles.container}>
            <div className={styles.headerWrapper}>
                <h3 className={styles.filterHeader}>{t('filter-label')}</h3>
                {areFiltersApplied ? (
                    <button className={styles.clearAllButton} type="button" onClick={() => clearFilters(null, true)}>
                        {t('filters-clear-all-label')}
                    </button>
                ) : null}
            </div>
            <div className={styles.filtersWrapper}>
                {nonEmptyFacets.map((facetData) => (
                    <FacetDrawer key={facetData.code} facetData={facetData} initialOpened={initialOpened} />
                ))}
            </div>
        </div>
    );
};

export default Filters;
