import { useEffect } from 'react';

import clsx from 'clsx';
import { isEmpty } from 'lodash';

import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import useConfigContext from '@nm-namshi-frontend/core/contexts/ConfigContext';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import Breadcrumbs from '@nm-namshi-frontend/core/page_components/shared/Breadcrumbs';
import ScrollToTop from '@nm-namshi-frontend/core/page_components/shared/ScrollToTop';
import useCatalogStore from '@nm-namshi-frontend/core/stores/useCatalogStore';
import { trackPage } from '@nm-namshi-frontend/core/utils/analytics';
import { convertPrice } from '@nm-namshi-frontend/core/utils/helper';
import { getImageUrl } from '@nm-namshi-frontend/core/utils/images';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { ProductFullResponse } from '@nm-namshi-frontend/services';
import PageCms from '@nm-namshi-frontend/core/page_components/shared/cms/PageCms';
import RatingsSummary from '@nm-namshi-frontend/core/page_components/productDetailPage/RatingsSummary';

import DetailsPageLayout from './DetailsPageLayout';
import styles from './ProductDetailPage.module.scss';

const ProductDetailPage = () => {
    const { catalogResponse } = useCatalog();
    const { locale } = useAppPathname();
    const { attributionData, isProductSponsored, plpIndex, plpPage, setAttributionData, setPlpIndex, setPlpPage } =
        useCatalogStore();
    const { conversionRates } = useConfigContext();

    let productData: ProductFullResponse | undefined;

    if (catalogResponse.type === 'details' && 'product' in catalogResponse.data) {
        productData = catalogResponse.data.product;
    }

    // tracking
    useEffect(() => {
        if (productData && locale && conversionRates) {
            const { currencyCode, currencyCodeEN } = getLocaleConfig(locale);
            const currentVariant =
                productData && productData.variants?.find((variant) => variant.sku === productData?.sku);
            let isOutOfStock = productData?.maxQty === 0;
            let netStock = productData?.maxQty || 0;
            let imageUrl = '';
            let productSize = 'OS';
            if (productData && productData.imageKeys && productData.imageKeys[0]) {
                imageUrl = getImageUrl(productData.imageKeys[0]);
            }
            if (currentVariant) {
                netStock = currentVariant.maxQty;
                isOutOfStock = currentVariant.maxQty === 0;
                if (currentVariant.title) {
                    productSize = currentVariant.title;
                }
            }

            const variant = currentVariant || productData;

            const productCurrentPrice = convertPrice({
                conversionRates,
                price: variant.salePrice || variant.normalPrice,
                toCurrency: currencyCode,
            });
            const productOriginalPrice = convertPrice({
                conversionRates,
                price: variant.normalPrice,
                toCurrency: currencyCode,
            });

            const gwpTrackingArray =
                productData.gwpOffers?.map((offer) => `${offer.filterKeyCode}|${offer.priority}`) || [];

            trackPage({
                event: 'page_pdp',
                product: productData,
                catalog: catalogResponse,
                pageUrl: window.location.href,
                sku: productData.sku,
                parentSku: productData.parentSku,
                netStock,
                isOOS: isOutOfStock,
                plpIndex,
                ...(plpPage ? { plpPage } : {}),
                productImageUrl: imageUrl,
                productOriginalPrice,
                productCurrentPrice,
                productPriceCurrency: currencyCode,
                productPriceCurrencyEN: currencyCodeEN,
                productSize,
                gwp: gwpTrackingArray,
                productTitle: productData.title || '',
                isSponsored: isProductSponsored,
                // A token that is part of the analytics object in /catalog response(usually for PLP's)
                ...(!isEmpty(attributionData) ? { attributionData } : {}),
                ...(!isEmpty(productData?.tags?.top_left)
                    ? { productTag: { topLeftTag: Object.keys(productData.tags?.top_left || {})?.[0] } }
                    : {}),
            });

            // Remove attribution token after event is tracked
            setAttributionData(null);
            // reset plpIndex
            setPlpIndex('');
            // reset plpPage
            setPlpPage(null);
        }
    }, [productData, locale, conversionRates]); // careful - do not add other deps

    if (!productData) return null;

    return (
        <section className={styles.container}>
            <ScrollToTop />
            <section className={clsx('siteWidthContainer', styles.content)}>
                <div className={styles.breadcrumbsWrapper}>
                    <Breadcrumbs />
                </div>

                <DetailsPageLayout />

                {'product' in catalogResponse.data && catalogResponse.data.cms && (
                    <PageCms cmsData={catalogResponse.data.cms} position="bottom" pageType="pdp" />
                )}

                {productData.reviewSummary && <RatingsSummary review={productData.reviewSummary} />}
            </section>
        </section>
    );
};

export default ProductDetailPage;
