import React, { useCallback, useState } from 'react';

import { isNull } from 'lodash';

import ProductImage from '@nm-namshi-frontend/core/page_components/shared/product/ProductImage';
import { IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import { ProductFullResponse } from '@nm-namshi-frontend/services';

import ImageZoomOverlay from './ImageZoomOverlay';
import styles from './ImageGallery.module.scss';

type TProps = {
    disableZoom?: boolean;
    productData: ProductFullResponse;
};

const ImageGallery: React.FC<TProps> = ({ productData }) => {
    // Zoom Overlay State Management
    const [activeZoomedImageIndex, setActiveZoomedImageIndex] = useState<number | null>(null);

    const { imageKeys = [], title } = productData;

    // Renderers

    const renderImages = useCallback(() => {
        if (!imageKeys) return null;
        return imageKeys.map((imageKey, index) => (
            <div
                tabIndex={-1 * (index + 1)}
                onKeyDown={() => setActiveZoomedImageIndex(index)}
                key={imageKey}
                onClick={() => setActiveZoomedImageIndex(index)}
            >
                <ProductImage
                    imageKey={imageKey}
                    size={IMAGE_SIZE.L}
                    altText={title || ''}
                    containerClassname={styles.imageContainer}
                />
            </div>
        ));
    }, [imageKeys]);

    const renderZoomOverlay = () => {
        const isZoomOverlayOpen = !isNull(activeZoomedImageIndex);
        return (
            <ImageZoomOverlay
                imageKeys={imageKeys}
                open={isZoomOverlayOpen}
                onClose={() => setActiveZoomedImageIndex(null)}
                {...(isZoomOverlayOpen ? { activeImageSlide: activeZoomedImageIndex } : {})}
                title={title || ''}
            />
        );
    };

    return (
        <div className={styles.container}>
            {renderImages()}
            {renderZoomOverlay()}
        </div>
    );
};

export default ImageGallery;
