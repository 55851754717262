/* eslint-disable @next/next/no-img-element */
import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import WishlistConversionCTA from '@nm-namshi-frontend/core/page_components/productDetailPage/WishlistConversionCTA';
import ProductPrice from '@nm-namshi-frontend/core/page_components/shared/product/ProductPrice';
import { ProductFullResponse } from '@nm-namshi-frontend/services';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import FouponToggle from '@nm-namshi-frontend/core/page_components/shared/FouponToggle';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import ProductBadge from '@nm-namshi-frontend/core/page_components/productDetailPage/ProductBadge';
import TimerSale, { TimerSaleVariant } from '@nm-namshi-frontend/core/page_components/shared/product/TimerSale';
import BuyNowPayLater from '@nm-namshi-frontend/core/page_components/shared/BNPL/BuyNowPayLater';
import { getIsBnplActive, getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import ProductDiscountTag from '@nm-namshi-frontend/core/page_components/shared/product/ProductDiscountTag';
import GiftWithPurchaseSwiper from '@nm-namshi-frontend/core/page_components/shared/GWP/GiftWithPurchaseSwiper';
import ShippingEstimates from '@nm-namshi-frontend/core/page_components/productDetailPage/ShippingEstimates';
import { Timer } from '@nm-namshi-frontend/services/models/Timer';
import AddToBagButton from '@nm-namshi-frontend/core/page_components/productDetailPage/AddToBagButton';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import useIsSettleXAddress from '@nm-namshi-frontend/core/hooks/useIsSettleXAddress';
import SizeGuide from '@nm-namshi-frontend/core/page_components/productDetailPage/SizeGuide';
import { getSelectedPrice, isOneSize } from '@nm-namshi-frontend/core/utils/helper';
import SizePills from '@nm-namshi-frontend/core/page_components/productDetailPage/SizePills';
import GroupedProducts from '@nm-namshi-frontend/core/page_components/productDetailPage/GroupedProducts';
import { ShareCatalog } from '@nm-namshi-frontend/core/page_components/shared/share/ShareCatalog';
import useCustomerStore from '@nm-namshi-frontend/core/stores/useCustomerStore';

import styles from './ProductConversion.module.scss';

type TProps = {
    productData: ProductFullResponse;
};

const ProductConversion = ({ productData }: TProps) => {
    const { t } = useTranslation('catalog');
    const { locale } = useAppPathname();
    const { lang, seoRegion } = getLocaleConfig(locale);
    const [isFouponApplied, setIsFouponApplied] = useState(false);

    const {
        brand,
        brandCode,
        discountTag,
        fouponText,
        groupName,
        groupedProducts = [],
        gwpOffers,
        isGlobal,
        isRocket,
        parentSku,
        sku,
        tags = {},
        timer,
        title,
        variants,
    } = productData;

    const [selectedSku, setSelectedSku] = useState<string>(isOneSize(variants) ? productData.sku : '');

    const [isSizeAlertShown, showSizeAlert] = useState(false);

    const selectedVariant = useMemo(
        () => variants?.find(({ sku: variantSku }) => variantSku === selectedSku),
        [!!variants, selectedSku],
    );

    const customerData = useCustomerStore((store) => store.customerData);

    const { discountPercent, normalPrice, salePrice } = getSelectedPrice(productData, selectedVariant);

    const { data: customerAddressesData, isLoading: isCustomerAddressesLoading } = useQuery(
        [REACT_QUERY_KEYS.GET_CUSTOMER_ADDRESSES],
        () => getApiInstance().customer.getCustomerAddresses({}),
        {
            enabled: !!customerData,
        },
    );

    const { isXAddressKeySattled } = useIsSettleXAddress();

    const { data: extrasData, isFetching: isExtrasLoading } = useQuery(
        [REACT_QUERY_KEYS.GET_CATALOG_EXTRAS, selectedSku],
        () =>
            getApiInstance().extras.catalogExtras({
                requestBody: {
                    uri: selectedVariant ? `/${selectedVariant.sku}/p` : `/${sku}/p`,
                },
                ...(customerAddressesData?.selected?.addressKey
                    ? { xAddressKey: customerAddressesData?.selected?.addressKey }
                    : {}),
            }),
        {
            enabled: isXAddressKeySattled,
        },
    );

    const areAddToBagPrerequisitesLoading = customerData
        ? isCustomerAddressesLoading || isExtrasLoading
        : isExtrasLoading;

    const brandUrl = `/${locale}/${brandCode}/`;

    const renderGroupedProducts = useCallback(() => {
        const doesGroupExist = Boolean(groupName && !!groupedProducts?.length);

        if (!doesGroupExist) return null;

        return <GroupedProducts productData={productData} />;
    }, [sku, groupName, groupedProducts]);

    // Renderers
    const RenderBrand = () => (
        <div className={styles.brand_share_wrapper}>
            <div>
                <AlteredLink
                    locale={locale}
                    href={brandUrl}
                    onClick={() =>
                        trackEvent({
                            event: 'brandClick',
                            brandCode,
                            contentClickUrl: `/${brandCode}`,
                        })
                    }
                >
                    <h2 className={styles.brand}>{brand}</h2>
                </AlteredLink>
                <h1 className={styles.productTitle}>{title}</h1>
            </div>
            <ShareCatalog
                outlined
                pageType="pdp"
                sku={selectedSku}
                referralMessage={t('shareLinkMessage')}
                onShare={({ url }) => {
                    trackEvent({
                        event: 'linkShare',
                        link: url,
                        pageType: 'pdp',
                    });
                }}
            />
        </div>
    );

    const RenderFouponToggle = () => {
        if (!fouponText) {
            return <></>;
        }

        // Managing the foupon state on UI as it will take effect only when sku is added to bag
        return (
            <FouponToggle
                fouponText={fouponText}
                isActive={isFouponApplied}
                sku={selectedSku}
                onExternalFouponToggle={(isChecked) => setIsFouponApplied(isChecked)}
            />
        );
    };

    const RenderBadge = () => {
        const { top_left = {} } = tags;
        const tagFirstValue = Object.values(top_left)[0] as string;
        if (!tagFirstValue && productData.isReturnable) return null;
        return (
            <div className={styles.badgesContainer}>
                {tagFirstValue && <ProductBadge key={tagFirstValue} text={tagFirstValue} variant="regular" onPDP />}
                {!productData.isReturnable && (
                    <ProductBadge key={t('nonReturnable')} text={t('nonReturnable')} variant="status" onPDP />
                )}
            </div>
        );
    };

    const showSizeSelector = !isOneSize(productData.variants);
    const shouldShowBNPL = getIsBnplActive(seoRegion);

    return (
        <section className={styles.container}>
            <section className={styles.product_detail}>
                <RenderBrand />
                <section className={styles.conversionSection}>
                    <div className={styles.priceContainer}>
                        <ProductPrice
                            size="x-large"
                            price={normalPrice}
                            salePrice={salePrice}
                            discountPercent={discountPercent}
                            containerClassname={styles.priceContainerOverride}
                        />
                        <div className={styles.vat}>{t('includeVAT')}</div>
                    </div>
                    <RenderBadge />
                    <GiftWithPurchaseSwiper offers={gwpOffers} />
                    <RenderFouponToggle />
                    <div className={styles.offerDetails}>
                        <TimerSale timerSaleConfig={timer as Timer} variant={TimerSaleVariant.banner} lang={lang} />
                        {discountTag && <ProductDiscountTag tag={discountTag} isClickable />}
                    </div>
                    <ShippingEstimates extrasData={extrasData} isExtrasLoading={isExtrasLoading} />
                </section>
            </section>
            {showSizeSelector && (
                <div>
                    <SizePills
                        selectedSku={selectedSku}
                        productData={productData}
                        renderSizeGuide={() => (
                            <SizeGuide
                                onSelect={(_sku) => {
                                    setSelectedSku(_sku);
                                    showSizeAlert(false);
                                }}
                                productData={productData}
                                selectedSku={selectedSku}
                                renderCTA={({ analyticsData, onSuccess }) => (
                                    <AddToBagButton
                                        isLoading={areAddToBagPrerequisitesLoading}
                                        productData={productData}
                                        selectedSku={selectedSku}
                                        extrasData={extrasData}
                                        disabled={!selectedSku}
                                        analyticsData={analyticsData}
                                        onSuccess={onSuccess}
                                        pageType="pdp"
                                    />
                                )}
                            />
                        )}
                        onSelect={(_sku) => {
                            setSelectedSku(_sku);
                            showSizeAlert(false);
                        }}
                    />
                    {isSizeAlertShown && <p className={styles.sizeAlert}>{t('no-size-selected')}</p>}
                </div>
            )}

            <section className={styles.ctas}>
                <div className={styles.addToBag}>
                    <AddToBagButton
                        isLoading={areAddToBagPrerequisitesLoading}
                        productData={productData}
                        selectedSku={selectedSku}
                        extrasData={extrasData}
                        onNoSizeSelected={() => showSizeAlert(true)}
                        analyticsData={{
                            eventLocation: 'pdp',
                            sizeSelected: selectedVariant?.title,
                        }}
                        pageType="pdp"
                    />
                </div>
                <div className={styles.addToWishlist}>
                    <WishlistConversionCTA
                        analyticsData={{
                            parentSku,
                            price: salePrice || normalPrice,
                            isRocket: !!isRocket,
                            isGlobal: !!isGlobal,
                        }}
                        sku={sku}
                        parentSku={parentSku}
                    />
                </div>
            </section>
            {renderGroupedProducts()}
            {shouldShowBNPL && (
                <div className={styles.bnplWrapper}>
                    <BuyNowPayLater amount={salePrice || normalPrice} />
                </div>
            )}
        </section>
    );
};

export default ProductConversion;
