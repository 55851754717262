import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { FreebieOffer, GWPOfferResponse } from '@nm-namshi-frontend/services';
import { IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import styles from './GiftWithPurchaseBanner.module.scss';
import ProgressBar from '../progressBar/ProgressBar';
import AlteredLink from '../AlteredLink';
import ProductImage from '../product/ProductImage';

type TGiftWithPurchaseBanner = {
    offer: GWPOfferResponse | FreebieOffer;
    showProgress?: boolean;
};
const GiftWithPurchaseBanner = ({ offer, showProgress = false }: TGiftWithPurchaseBanner) => {
    const { locale } = useAppPathname();
    const { t } = useTranslation();

    const { currencyCode, isArabic } = getLocaleConfig(locale);

    const arrowIcon = isArabic ? 'leftChevronRounded' : 'rightChevronRounded';

    return (
        <div className={styles.wrapper}>
            <div className={styles.label}>{t('gwp.free-gift')}</div>
            <div className={styles.gwpDetails}>
                <div className={styles.firstSection}>
                    <div className={styles.giftImg}>
                        <ProductImage
                            {...('imageKey' in offer ? { imageKey: offer.imageKey } : {})}
                            size={IMAGE_SIZE.S}
                            altText={t('gwp.free-gift')}
                        />
                    </div>
                    <div>
                        <div className={styles.title}>{offer.title}</div>
                        <div className={styles.description}>{offer.description}</div>
                    </div>
                </div>
                {!showProgress && (
                    <AlteredLink
                        className={styles.arrow}
                        locale={locale}
                        href={offer.filterKeyCode || ''}
                        onClick={() => {
                            trackEvent({ event: 'gwpClick', contentClickUrl: offer.filterKeyCode || '' });
                        }}
                    >
                        <Icon size={22} name={arrowIcon} />
                    </AlteredLink>
                )}
            </div>
            {showProgress && (
                <div className={styles.gwpProgress}>
                    {'percentage' in offer && <ProgressBar progress={Number(offer.percentage)} />}
                    <div className={styles.progressDescription}>
                        <div>
                            {'unlocked' in offer && !offer.unlocked ? (
                                <Trans
                                    t={t}
                                    i18nKey="gwp.add-to-unlock"
                                    values={{
                                        amount:
                                            offer.type === 'amount'
                                                ? `${currencyCode} ${offer.left} `
                                                : `${offer.left} ${Number(offer.left) > 1 ? t('items') : t('item')}`,
                                    }}
                                    components={{
                                        strong: <strong />,
                                    }}
                                />
                            ) : (
                                <div className={styles.successMessage}>
                                    {t('gwp.unlocked-message')}
                                    <Icon size={18} name="greenTick" />
                                </div>
                            )}
                        </div>
                        {'unlocked' in offer && !offer.unlocked && (
                            <AlteredLink
                                className={styles.addProducts}
                                locale={locale}
                                href={offer.filterKeyCode}
                                onClick={() => {
                                    trackEvent({ event: 'gwpClick', contentClickUrl: offer.filterKeyCode });
                                }}
                            >
                                {offer.cta}
                                <Icon color="systemBlue" size={18} name="chevronForward" />
                            </AlteredLink>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default GiftWithPurchaseBanner;
