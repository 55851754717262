// #TODO: Revisit the image zoom

import React from 'react';

import LightBox from '@nm-namshi-frontend/core/components/LightBox';
import ImageGalleryCarousel from '@nm-namshi-frontend/core/page_components/shared/product/ImageGalleryCarousel';
import { IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';

import styles from './ImageZoomOverlay.module.scss';

type TProps = {
    open: boolean;
    title: string;
    onClose: () => void;
    activeImageSlide?: number;
    imageKeys: Array<string>;
};

const ImageZoomOverlay = ({ activeImageSlide = 0, imageKeys, onClose, open, title }: TProps) => {
    const renderImageGalleryCarousel = () => (
        <div className={styles.mainCarouselContainer}>
            <ImageGalleryCarousel
                activeSlide={activeImageSlide}
                imageKeys={imageKeys}
                imageSize={IMAGE_SIZE.L}
                isDotsVisible={false}
                isNavigationVisible={false}
                areThumbnailsVisible
                altText={title || ''}
                lazyLoad={false}
            />
        </div>
    );
    if (open) {
        return (
            <LightBox open={open} onClose={onClose}>
                {renderImageGalleryCarousel()}
            </LightBox>
        );
    }
    return null;
};

export default ImageZoomOverlay;
