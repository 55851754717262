'use client';

import React, { useEffect, useMemo } from 'react';

import { get } from 'lodash';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';

import { initApiInstance } from '@nm-namshi-frontend/core/api';
import { ClientApp, TLocaleSEO, NavDepartmentsResponse } from '@nm-namshi-frontend/core/types';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import ErrorView from '@nm-namshi-frontend/core/page_components/shared/error/ErrorView';
import { addRecentlyViewedItem } from '@nm-namshi-frontend/core/utils/catalog';
import { CatalogResponse } from '@nm-namshi-frontend/services';
import { CatalogContextProvider } from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { ERRORVIEW } from '@nm-namshi-frontend/core/constants/uiConstants';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import PopularSearches from '@nm-namshi-frontend/core/page_components/shared/PopularSearches';
import useDepartmentStoreV2 from '@nm-namshi-frontend/core/stores/useDepartmentStoreV2';
import { getSelectedDepartmentOrSubDepartmentData } from '@nm-namshi-frontend/core/utils/departments';

import ProductDetailPage from '../../../components/pdp/ProductDetailPage';
import ProductListingPage from '../../../components/plp/ProductListingPage';
import StaticPage from '../../../components/static/StaticPage';

type TProps = {
    params: { locale: TLocaleSEO; catalog_url: Array<string> };
    searchParams?: string | URLSearchParams | string[][] | Record<string, string> | undefined;
    catalogResponse: CatalogResponse | { error: string; status: number };
    navigationData: NavDepartmentsResponse | { error: string; status: number };
    selectedDepartmentId: string;
    selectedSubDepartmentId: string;
};

const CatalogClient = ({
    catalogResponse,
    navigationData,
    params,
    searchParams,
    selectedDepartmentId,
    selectedSubDepartmentId,
}: TProps): JSX.Element => {
    const { locale } = params;
    const { apiLocale } = getLocaleConfig(locale);
    const router = useRouter();
    const { t } = useTranslation('common');

    const selectedDepartmentIdFromStore = useDepartmentStoreV2((state) => state.selectedDepartmentId);
    const selectedSubDepartmentIdFromStore = useDepartmentStoreV2((state) => state.selectedSubDepartmentId);

    const setSelectedDepartmentOrSubDepartmentId = useDepartmentStoreV2(
        (store) => store.setSelectedDepartmentOrSubDepartmentId,
    );

    const popularSearchesList = useMemo(() => {
        if (!!catalogResponse && 'seo' in catalogResponse) return catalogResponse.seo?.footerPopularSearches;
        return null;
    }, [catalogResponse]);

    const selectedDepartmentOrSubDepartmentData = useMemo(
        () =>
            getSelectedDepartmentOrSubDepartmentData({
                navigationData,
                selectedDepartmentId: selectedDepartmentIdFromStore,
                selectedSubDepartmentId: selectedSubDepartmentIdFromStore,
            }),
        [selectedSubDepartmentIdFromStore, selectedSubDepartmentIdFromStore, navigationData],
    );

    useEffect(() => {
        // Update store
        if (selectedDepartmentOrSubDepartmentData?.id) {
            setSelectedDepartmentOrSubDepartmentId(selectedDepartmentOrSubDepartmentData.id);
        }
    }, [selectedDepartmentOrSubDepartmentData]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        if ('canonicalUrl' in catalogResponse && catalogResponse.type === 'details') {
            const detailPageCollapseField = get(catalogResponse, 'data.product.collapseField', '');
            const detailPageSku = get(catalogResponse, 'data.product.sku', '');

            addRecentlyViewedItem(detailPageCollapseField, detailPageSku);
        }
    }, [catalogResponse]);

    if ('error' in catalogResponse) {
        trackEvent({ event: 'brokenCatalog' });
        // eslint-disable-next-line no-console
        if ('status' in catalogResponse && catalogResponse.status === 404) {
            return (
                <ErrorView
                    actionText={t('back-cta')}
                    type={ERRORVIEW.CATALOG_NOT_FOUND}
                    onAction={() => router.back()}
                />
            );
        }

        return <ErrorView actionText={t('retry')} onAction={() => window.location.reload()} />;
    }

    if (locale) {
        initApiInstance({ app: ClientApp.BIGALOG, locale: apiLocale });
    }

    const renderCatalog = () => {
        switch (catalogResponse.type) {
            case 'static':
                return <StaticPage />;
            case 'listing':
                return <ProductListingPage />;
            case 'details':
                return <ProductDetailPage />;
            default:
                return <ErrorView actionText={t('retry')} onAction={() => window.location.reload()} />;
        }
    };

    return (
        <CatalogContextProvider
            catalogResponse={catalogResponse}
            searchParams={searchParams}
            navigationData={navigationData as NavDepartmentsResponse}
            selectedDepartmentId={selectedDepartmentId}
            selectedSubDepartmentId={selectedSubDepartmentId}
        >
            {renderCatalog()}
            <PopularSearches locale={params.locale} searchesList={popularSearchesList} />
        </CatalogContextProvider>
    );
};

export default CatalogClient;
