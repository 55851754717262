import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { ModulesRenderer } from '@nm-namshi-frontend/widgets';
import { TSectionLayout } from '@nm-namshi-frontend/widgets/src/types/ModulesRenderer';
import getImages from '@nm-namshi-frontend/core/utils/images';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import RecentlyViewedCarousel from '@nm-namshi-frontend/core/page_components/shared/RecentlyViewedCarousel';
import { getPLPAnalyticsData } from '@nm-namshi-frontend/core/utils/catalog';

import styles from './EmptyListingPage.module.scss';

const EmptyListingPage = () => {
    const { t } = useTranslation('catalog');
    const { catalogResponse, listingPagehasNoProducts } = useCatalog();

    const analyticsData = useMemo(() => getPLPAnalyticsData(catalogResponse), [catalogResponse]);

    const shouldShowErrorMessage =
        !listingPagehasNoProducts || (listingPagehasNoProducts && isEmpty(catalogResponse.data));

    return (
        <div className={styles.container}>
            {shouldShowErrorMessage && (
                <>
                    <img alt="empty-search" height={100} src={getImages().emptyStates.catalog} width={100} />
                    <h3 className={styles.title}>{t('empty-search-title')}</h3>
                    <span className={styles.subtitle}>{t('empty-search-description')}</span>
                </>
            )}

            <div className={styles.carouselWrapper}>
                {listingPagehasNoProducts && !isEmpty(catalogResponse.data) ? (
                    <ModulesRenderer
                        catalogData={catalogResponse}
                        eventLocation="plp"
                        data={catalogResponse.data as TSectionLayout[]}
                        device="desktop"
                        analyticsData={analyticsData}
                    />
                ) : (
                    <RecentlyViewedCarousel analyticsData={{ pageType: 'listing' }} />
                )}
            </div>
        </div>
    );
};

export default EmptyListingPage;
