import ProductListGrid from './ProductListGrid';
import styles from './ProductListingContent.module.scss';
import TotalCountAndSort from './TotalCountAndSort';

const ProductListingContent = () => (
    <div className={styles.container} data-testid="ProductListingContent">
        <TotalCountAndSort />
        <ProductListGrid />
    </div>
);

export default ProductListingContent;
