import { useMemo } from 'react';

import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import MultiWidgetPage from '@nm-namshi-frontend/core/page_components/shared/cms/MultiWidgetPage';
import { getPLPAnalyticsData } from '@nm-namshi-frontend/core/utils/catalog';

const ProductListGrid = () => {
    const { catalogResponse } = useCatalog();

    const modules = catalogResponse.data;

    const analyticsData = useMemo(() => getPLPAnalyticsData(catalogResponse), [catalogResponse]);

    if (!Array.isArray(modules)) {
        // #TODO REPORT : Incorrect data structure;
        return <div />;
    }

    return (
        <MultiWidgetPage
            modules={modules}
            device="desktop"
            pageType="plp"
            analyticsData={analyticsData}
            innerContainerStyles={{
                gap: 'var(--plp-spacing)',
                marginTop: 'var(--plp-spacing)',
            }}
        />
    );
};

export default ProductListGrid;
