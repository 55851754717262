import { AnalyticsResponse, SectionLayout } from '@nm-namshi-frontend/services';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import { ModulesRenderer } from '@nm-namshi-frontend/widgets/src';
import { TDevice } from '@nm-namshi-frontend/core/types';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { TSectionLayout } from '@nm-namshi-frontend/widgets/src/types/ModulesRenderer';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import { TCMSPageType, TPageType } from '@nm-namshi-frontend/core/utils/analytics.types';

type TProps = {
    modules: SectionLayout[];
    device: TDevice;
    pageType: TCMSPageType;
    analyticsData: {
        pageType: TPageType;
        plpPage?: number; // In case of a PLP being rendered
        searchTerm?: string;
        attributionData?: AnalyticsResponse['td'];
    };
    innerContainerStyles?: React.CSSProperties;
};

const MultiWidgetPage: React.FC<TProps> = ({ analyticsData, device, innerContainerStyles, modules, pageType }) => {
    const { locale } = useAppContext();
    const { lang } = getLocaleConfig(locale);

    const { catalogResponse } = useCatalog();

    return (
        <ModulesRenderer
            eventLocation={pageType}
            device={device}
            data={modules as TSectionLayout[]}
            language={lang}
            catalogData={catalogResponse}
            analyticsData={analyticsData}
            innerContainerStyles={innerContainerStyles}
        />
    );
};

export default MultiWidgetPage;
