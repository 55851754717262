import React from 'react';

import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';

import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getIsBnplActive, getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useConfigContext from '@nm-namshi-frontend/core/contexts/ConfigContext';
import { convertPrice } from '@nm-namshi-frontend/core/utils/helper';
import { BNPL_INSTALLMENT_COUNT } from '@nm-namshi-frontend/core/config';
import { TConversionRates } from '@nm-namshi-frontend/core/types';

import TabbySnippet from './TabbySnippet';
import TamaraSnippet from './TamaraSnippet';
import styles from './BuyNowPayLater.module.scss';

type TProps = {
    amount: number;
    className?: string;
    titleClassName?: string;
    // for BNPL items
    containerStyle?: string;
};

const BuyNowPayLater = ({ amount, className, containerStyle, titleClassName }: TProps) => {
    const { t } = useTranslation('common');

    const { locale } = useAppPathname();
    const { currencyCode, lang, seoRegion } = getLocaleConfig(locale);
    const { conversionRates } = useConfigContext();
    const shouldShowBNPL = getIsBnplActive(seoRegion);

    const localAmount = convertPrice({
        conversionRates: conversionRates as TConversionRates,
        price: amount,
        toCurrency: currencyCode,
    });

    if (!localAmount || !isNumber(localAmount) || !currencyCode || !lang) return null;

    const installmentAmount = (Math.round((localAmount * 100) / BNPL_INSTALLMENT_COUNT) / 100).toFixed(2);

    const renderInstallmentDescription = () => {
        if (!installmentAmount) return null;

        return (
            <span>
                <Trans t={t} i18nKey="payments._tabby-snippet-installments-desc">
                    {{ installments: BNPL_INSTALLMENT_COUNT }} interest-free payments of{' '}
                    <span>
                        {{ currency: currencyCode }} {{ amount: Number(installmentAmount).toLocaleString() }}
                    </span>
                </Trans>
            </span>
        );
    };

    if (!shouldShowBNPL) return null;

    return (
        <section className={clsx(className, styles.bnpl)}>
            <div className={clsx(titleClassName, styles.label)}>{renderInstallmentDescription()}</div>
            <div className={styles.iconsWrapper}>
                <TabbySnippet amount={amount} containerStyle={containerStyle} />
                <div className={styles.divider} />
                <TamaraSnippet amount={amount} containerStyle={containerStyle} />
            </div>
        </section>
    );
};

export default BuyNowPayLater;
