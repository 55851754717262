import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace-builder/bigalog/app/[locale]/[[...catalog_url]]/catalogClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace-builder/bigalog/components/HeaderWithDepartmentTabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace-builder/bigalog/components/HomeBrandsBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace-builder/bigalog/components/HomePageBanners.tsx");
;
import(/* webpackMode: "eager" */ "/workspace-builder/bigalog/components/layouts/DefaultLayout.module.scss");
;
import(/* webpackMode: "eager" */ "/workspace-builder/bigalog/components/HomePage.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace-builder/core/page_components/shared/error/ErrorView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace-builder/core/page_components/shared/footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace-builder/core/page_components/shared/loader/FullScreenLoader.tsx");
