import PlpPagination from '@nm-namshi-frontend/core/components/PlpPagination';
import useCatalog from '@nm-namshi-frontend/core/contexts/CatalogContext';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';

type TProps = {
    className: string;
};

const PaginationContainer = ({ className }: TProps) => {
    const { catalogResponse } = useCatalog();
    const { locale } = useAppPathname();

    const searchMeta = catalogResponse.nav?.searchMeta;

    if (!searchMeta) {
        // #TODO Report
        return <div />;
    }

    return (
        <PlpPagination
            className={className}
            currentPage={searchMeta.currentPage}
            totalPages={searchMeta.totalPages}
            locale={locale}
        />
    );
};

export default PaginationContainer;
