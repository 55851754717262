import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import useToggleFollowBrand from '@nm-namshi-frontend/core/hooks/useToggleFollowBrand';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import { getBrandUrl } from '@nm-namshi-frontend/core/utils/brands';
import { ProductFullResponse } from '@nm-namshi-frontend/services';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';

import HeartIcon from './HeartIcon';
import styles from './PDPBrand.module.scss';

type TPDPBrandProps = {
    productData: ProductFullResponse;
};

const PDPBrand: React.FC<TPDPBrandProps> = ({ productData }) => {
    const { brand, brandCode, brandLogoUrl } = productData;

    const { t } = useTranslation();
    const { locale } = useAppPathname();
    const queryClient = useQueryClient();

    const { data, isLoading: isLoadingFollowStatus } = useQuery(
        [REACT_QUERY_KEYS.BRAND_LIST, 'following'],
        () =>
            getApiInstance().brand.getBrandList({
                requestBody: {
                    brandListType: 'following',
                },
            }),
        {
            refetchOnMount: false,
        },
    );

    const { isLoading, toggleFollow } = useToggleFollowBrand({
        invalidate: () => queryClient.invalidateQueries([REACT_QUERY_KEYS.BRAND_LIST, 'following']),
        onSuccess(followedBrandsData) {
            trackEvent({
                event: followedBrandsData.isFollowing ? 'brandFollow' : 'brandUnfollow',
                brandCode: followedBrandsData.brandCode,
                pageType: 'pdp',
            });
        },
    });

    const isFollowed = useMemo(() => !!data?.brands.find((item) => item.brandCode === brandCode)?.isFollowing, [data]);

    return (
        <div className={styles.container}>
            <AlteredLink locale={locale} className={styles.link} href={getBrandUrl(brandCode, null, false)}>
                <div className={styles.imageWrapper}>
                    {brandLogoUrl ? <img src={brandLogoUrl} alt={brand} /> : <div>{brand.charAt(0)}</div>}
                </div>
                <div className={styles.textWrapper}>
                    <div className={styles.name}>{brand}</div>
                    <div className={styles.followDetails}>{t('followDetails')}</div>
                </div>
            </AlteredLink>
            <button
                disabled={isLoading}
                className={styles.followBtn}
                type="button"
                onClick={() => toggleFollow({ brandCode, isFollowing: !isFollowed })}
            >
                <HeartIcon isLoading={isLoading || isLoadingFollowStatus} active={isFollowed} size={17} />
                {isFollowed ? t('unfollow') : t('follow')}
            </button>
        </div>
    );
};

export default PDPBrand;
