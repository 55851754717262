/* eslint-disable react/no-danger */

// react component to render raw html with dangerouslySetInnerHTML
import { FC } from 'react';

type TRawHTMLProps = {
    html: string;
    className?: string;
};
export const RawHTML: FC<TRawHTMLProps> = ({ className = '', html }: TRawHTMLProps) => (
    <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
);
export default RawHTML;
